// src/pages/secret-revealed.js
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

const SecretContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 20px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const SecretRevealed = () => (
  <Layout>
    <Helmet>
      <title>Secret Revealed | My Website</title>
    </Helmet>
    <SecretContainer>
      <Title>Congratulations!</Title>
      <p>You have solved the puzzle and revealed the secret!</p>
    </SecretContainer>
  </Layout>
);

export default SecretRevealed;
